import React, {Component} from 'react';
import { Button, Form } from 'react-bootstrap';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';
import Axe from './Axe';

class PopoverEditAxe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: null,
      staticData: null,
      itemId: null,
      itemType: null,
      currentView: {},
      columnName: null,
      columnType: null,
      axeValue: null,
      restrictedValues: [],
      displayMore: false,
      filteredValues: [],
      search: ''
    };

    this.displayMoreValues = this.displayMoreValues.bind(this);
    this.searchItem = this.searchItem.bind(this);
    this.pressKey = this.pressKey.bind(this);
    this.updateAxe = this.updateAxe.bind(this);

    this.search = React.createRef();
  }

  componentDidMount() {
    const language = Authentication.getCookie('language');
    const staticData = Authentication.getCookie('staticData');
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const currentView = this.props.CurrentView;
    const columnName = this.props.ColumnName;
    const columnType = this.props.ColumnType;
    const axeValue = this.props.AxeValue;
    const restrictedValues = this.props.RestrictedValues;
    let filteredValues = [];

    // Limit to 100 values
    restrictedValues.forEach((value, index) => {
      if(index < 100) {
        filteredValues.push(value);
      }
    });

    // Search Auto focus
    if(columnName !== 'Status' && columnName !== 'Impact' && columnName !== 'Priority' && columnName !== 'Probability' && columnName !== 'Meteo' && columnName !== 'Trend'  && columnName !== 'Language') {
      setTimeout(() => { this.search.current.focus() }, 1);
    }

    this.setState({ language, staticData, itemId, itemType, currentView, columnName, columnType, axeValue, restrictedValues, filteredValues });
  }

  componentDidUpdate(prevProps) {
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const currentView = this.props.CurrentView;
    const columnName = this.props.ColumnName;
    const columnType = this.props.ColumnType;
    const axeValue = this.props.AxeValue;
    const restrictedValues = this.props.RestrictedValues;

    // if(this.props.ItemId !== prevProps.ItemId || this.props.ItemType !== prevProps.ItemType) {
    //   this.setState({ itemId, itemType });
    // }
    if(JSON.stringify(this.props.CurrentView) !== JSON.stringify(prevProps.CurrentView)) {
      this.setState({ currentView });
    }
    // if(this.props.ColumnName !== prevProps.ColumnName || this.props.AxeValue !== prevProps.AxeValue || this.props.RestrictedValues !== prevProps.RestrictedValues) {
    //   this.setState({ columnName, axeValue, restrictedValues });
    // }
  }

  displayMoreValues() {
    const restrictedValues = this.state.restrictedValues;
    const search = this.state.search;
    let filteredValues = [];

    restrictedValues.forEach((value, index) => {
      if(search === "") {
        filteredValues.push(value);
      }
      else if(value.Label.toLowerCase().includes(search.toLowerCase())) {
        filteredValues.push(value);
      }
    });

    this.setState({ displayMore: true, filteredValues });
  }

  searchItem(event) {
    const restrictedValues = this.state.restrictedValues;
    const search = event.target.value;
    let filteredValues = [];

    restrictedValues.forEach((value, index) => {
      // Search empty & limit to 100 values
      if(index < 100 && search === "") {
        filteredValues.push(value);
      }
      // Values includes search & limit to 100 values
      else if(search !== "" && filteredValues.length < 100) {
        if(value.Label.toLowerCase().includes(search.toLowerCase())) {
          filteredValues.push(value);
        }
      }

      if(filteredValues.length < 100) {
        this.setState({ displayMore: true });
      }
      else {
        this.setState({ displayMore: false });
      }
    });
    
    this.setState({ filteredValues, search });
  }

  pressKey(event) {
    const { itemId, itemType, columnName, axeValue, restrictedValues, filteredValues } = this.state;
    const search = event.target.value;
    let newValue;

    if(event.key === 'Enter' && search !== '') {
      if(filteredValues.find(value => value.Label.toLowerCase().includes(search.toLowerCase()))) {
        newValue = filteredValues.find(value => value.Label.toLowerCase().includes(search.toLowerCase()));
        // newValue = restrictedValues.find(value => value.Label.toLowerCase().includes(search.toLowerCase()));

        // Close Popover
        if(document.getElementById("popover-basic")) {
          document.getElementById("popover-basic").classList.remove("show");
        }
        
        if(newValue.Label !== axeValue.Label) {
          // Call the event from the Parent component through the props
          this.props.onAxeEdit(itemId, itemType, columnName, JSON.stringify(axeValue), JSON.stringify(newValue));
        }

        this.setState({ axeValue: newValue });
      }
    }
  }

  updateAxe(itemId, itemType, columnName, oldValue, newValue) {
    // Close Popover
    if(document.getElementById("popover-basic")) {
      document.getElementById("popover-basic").classList.remove("show");
    }
    
    if(newValue != oldValue) {
      // Call the event from the Parent component through the props
      this.props.onAxeEdit(itemId, itemType, columnName, oldValue, newValue);
    }
  }

  render() {
    const { language, staticData, itemId, itemType, currentView, columnName, columnType, axeValue, restrictedValues, displayMore, filteredValues, search } = this.state;

    return (
      <div className="axeEditPopover">
        {/* Search */}
        {(columnName !== 'Status' && columnName !== 'Impact' && columnName !== 'Priority' && columnName !== 'Probability' && columnName !== 'Meteo' && columnName !== 'Trend'  && columnName !== 'Language') && 
          <div className="axeEditSearch">
            <Form.Group className="searchBlock">
              <Form.Control type="text" id="searchFilters" name="search" value={search} placeholder={Traduction.translate(language, 'search')} autoFocus onChange={this.searchItem} onKeyDown={this.pressKey} ref={this.search}/>
            </Form.Group>
          </div>
        }
        {/* Values */}
        <div className="axeEditValues scrollbar-y">
          {/* Current Value */}
          {axeValue && <div className="axeCurrentValue" onClick={() => this.updateAxe(itemId, itemType, columnName, JSON.stringify(axeValue), JSON.stringify(axeValue))}>
            <Axe CurrentView={currentView} Axe={columnName} Type={columnType} Value={axeValue} View={'Popover'}></Axe>
          </div>}
          {/* Border */}
          <div className="popoverBorder"></div>
          {/* Other Values */}
          {filteredValues.map((value, index) => {
            if(value.Id !== axeValue.Id) {
              return <div key={index} className="axeEditValue" onClick={() => this.updateAxe(itemId, itemType, columnName, JSON.stringify(axeValue), JSON.stringify(value))}>
                <Axe CurrentView={currentView} Axe={columnName} Type={columnType} Value={value} View={'Popover'}></Axe>
              </div>;
            }
          })}
          {/* {restrictedValues.map((value, index) => {
            if(value.Id !== axeValue.Id) {
              if(search === "" || value.Label.toLowerCase().includes(search.toLowerCase())) {
                return <div key={index} className="axeEditValue" onClick={() => this.updateAxe(itemId, itemType, columnName, JSON.stringify(axeValue), JSON.stringify(value))}>
                  <Axe CurrentView={currentView} Axe={columnName} Type={columnType} Value={value} View={'Popover'}></Axe>
                </div>;
              }
            }
          })} */}
        </div>
        {/* Display more values */}
        {!displayMore && restrictedValues.length >= 100 && <div className="axeEditValuesButton">
          <Button className="axeEditButton" variant="primary" onClick={() => this.displayMoreValues()}>{Traduction.translate(language, 'display_more')}</Button>
        </div>}
        {/* Edit Axis Values */}
        {staticData === 'true' && columnType !== 'Resource' && columnName !== 'Business_Line' && columnName !== 'Project' && columnName !== 'Workpackage' && columnName !== 'Action' && columnName !== 'Task' && columnName !== 'Entity' && columnName !== 'Sprint' && columnName !== 'Meeting' && columnName !== 'Status' && columnName !== 'FieldType' && columnName !== 'Impact' && columnName !== 'Priority' && columnName !== 'Probability' && columnName !== 'Meteo' && columnName !== 'Trend' && columnName !== 'Owner' && columnName !== 'Card_Level' && columnName !== 'Language' && columnName !== 'Format_Date' && 
          <div className="axeEditValuesButton">
            <a target="_self" href={`/Environment/AxisValues?axis=${columnName}`}><Button className="axeEditButton" variant="primary">{Traduction.translate(language, 'edit_values')}</Button></a>
          </div>
        }
      </div>
    );
  }
}

export default PopoverEditAxe;